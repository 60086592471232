<template>
  <vue-good-table
    class="position-static"
    mode="remote"
    :is-loading="isLoading"
    :columns="columns"
    :rows="rows"
    :rtl="directionIsRTL"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: perPage,
    }"
    style-class="vgt-table striped"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
  >
    <template
      slot="table-row"
      slot-scope="props"
    >
      <div
        v-if="props.column.field === 'email_subject'"
        cols="12"
        md="2" 
        class="justify-content-between flex-wrap"
      >
        <b-link @click="editCommunication(props.row)">
          {{ props.row.email_subject }}
        </b-link>
      </div>

      <div
        v-else-if="props.column.field === 'recipient'"
        cols="12"
        md="2"
        class="justify-content-between flex-wrap"
      > 
        <div v-if="!props.row.recipient" class="text-danger text-left">
          <feather-icon  icon="AlertCircleIcon" size="32"  v-b-tooltip.hover.top="'Please add a group to send the communications too'" />
        </div>
          {{ props.row.recipient }}
      </div>


      <!-- Column: Actions -->
      <div
        v-else-if="props.column.field === 'actions'"
        cols="12"
        md="2"
        class="vgt-center-align d-flex align-items-center"
      >
        <b-button
          variant="outline-none"
          class="nav pr-0"
        >

          <b-nav-item-dropdown
            class="btn-icon"
            dropleft
          >
            <template 
              #button-content
            >
              <feather-icon
                icon="MoreVerticalIcon"
                size="20"
              />                
            </template>
            <action-dropdown-item
              v-b-tooltip.hover.html.righttop="
                !canSendNow(props.row)
                  ? `
                <div class='text-left'>Send now option activates when:
                <br/>- Message status is enabled<br/>- Message is scheduled for a key date, 
                a participant action, or a custom date (absolute date)</div>`
                  : ''
              "
              link-class="d-flex align-items-center"
              :disabled="!canSendNow(props.row)"
              @click="canSendNow(props.row) ? sendNow(props.row) : null"
            >
              <feather-icon
                size="16"
                icon="SendIcon"
                class="mr-50"
              />
              <span>Send Now</span>
            </action-dropdown-item>
            <b-dropdown-item
              link-class="d-flex align-items-center"
              @click="viewSentMessageList(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="BarChart2Icon"
                class="mr-50"
              />
              <span>Sent messages</span>
            </b-dropdown-item>
            <action-dropdown-item
              link-class="d-flex align-items-center"
              @click="deleteProgramCommunication(props.row.id)"
            >
              <feather-icon
                size="16"
                icon="TrashIcon"
                class="mr-50"
              />
              <span>Delete</span>
            </action-dropdown-item>
          </b-nav-item-dropdown>
        </b-button>
      </div>
      <!-- Column: Enable -->
      <div v-else-if="props.column.field === 'is_enabled'">
        <action-checkbox
          :checked="props.row.is_enabled"
          switch
          inline
          @clickNativePrevent="toggleCommunicationStatus(props.row)"
        />
      </div>
      <!-- Column: Common -->
      <span v-else>
        {{ props.formattedRow[props.column.field] }}
      </span>
    </template>
    <!-- pagination -->
    <template
      slot="pagination-bottom"
      slot-scope="props"
    >
      <table-pagination
        :per-page="perPage"
        :total="total"
        @perPageChanged="
          (value) => props.perPageChanged({ currentPerPage: value })
        "
        @pageChanged="(value) => props.pageChanged({ currentPage: value })"
      />
    </template>
  </vue-good-table>
</template>

<script>
import { BButton, VBToggle, BLink, VBTooltip, BNavItemDropdown, BDropdownItem, BFormCheckbox } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import TablePagination from "@/views/components/pagination/TablePagination.vue";
import {
  makeSuccessToast,
  makeErrorToast,
  formatDateTimeFromIsoDateTime,
} from "@/libs/utils";
import { commsService }  from "@/services";
import { recipientTypes, topicChannel, topicTypes } from '@models';
import { useGetAvailability } from "@/views/apps/useGetAvailability";
import useAomTableConfig from "@aom-core/useAomTableConfig.js";
import moment from "moment";
import ActionDropdownItem from "@/views/components/common/ActionDropdownItem.vue";
import ActionCheckbox from "@/views/components/common/ActionCheckbox.vue";

export default {
  components: {
    ActionDropdownItem,
    ActionCheckbox,
    VueGoodTable,
    BButton,
    TablePagination,
    BLink,
    BNavItemDropdown,
    BDropdownItem,
    BFormCheckbox
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      isLoading: false,
      element: {},
      dir: false,
      columns: [
        {
          label: "Email Subject",
          field: "email_subject",
          filterOptions: {
            enabled: true,
            placeholder: "Search Email Subject",
          },
          width: "10em",
        },
        {
          label: "Name (Internal)",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name (Internal)",
          },
          width: "10em",
        },

        {
          label: "Recipient",
          field: "recipient",
          filterOptions: {
            enabled: true,
            placeholder: "Search Recipient",
          },
        },

        {
          label: "Availability",
          field: "availability",
          filterOptions: {
            enabled: false,
          },
          width: "12em",
          sortable: true
        },

        {
          label: "Total Sent",
          field: "topic_sent_messages_count",
          filterOptions: {
            enabled: false,
          },
          width: "8em",
          sortable: false
        },

        {
          label: "Enabled",
          field: "is_enabled",
          width: "8em",
        },

        {
          field: "actions",
          sortable: false,
          width: "8em",
        },
      ],
      columnFilters: [],
      sort: [],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    directionIsRTL() {
      return store.state.appConfig.isRTL;
    },
  },
  created() {
    this.loadItems();
  },
  methods: {
    viewSentMessageList(id) {
      this.$router.push({
        name: "champion-program-schedules-sent-messages",
        params: {
          id: this.$route.params.id,
          topicId: id
        }
      });
    },
    dateFilter(date, filterString) {
      return (date = Date.parse(date) >= Date.parse(filterString));
    },

    onPageChange(params) {
      this.page = params.currentPage;
      this.loadItems();
    },

    onPerPageChange(params) {
      this.perPage = params.currentPerPage;
      this.loadItems();
    },

    onSortChange(params) {
      let field = params[0].field;
      let type = params[0].type;
      if (type === 'none') {
        this.sort = [];
      } else {
        if (params[0].field === "email_subject") {
          field = "messages.subject";
        } else if (params[0].field === "recipient") {
          field = "topic_recipients.id";
        } else if (params[0].field === "availability") {
          field = "scheduled_action.calculated_date";
        }
        this.sort = [{ field, type: type }];
      }
      this.loadItems();
    },

    onColumnFilter(params) {
      const columnFilters = [];
      for (let col of [
        "email_subject",
        "name",
        "recipient",
      ]) {
        if (params.columnFilters[col]) {
          if (col === "email_subject") {
            columnFilters.push({
              field: "messages.subject",
              value: params.columnFilters[col],
            });
          } else if (col === "recipient") {
            columnFilters.push({
              field: "topic_recipients.recipient.name",
              value: params.columnFilters[col],
            });
          } else {
            columnFilters.push({
              field: col,
              value: params.columnFilters[col],
            });
          }
        }
      }
      this.columnFilters = columnFilters;
      this.loadItems();
    },

    async loadItems() {
      try {
        this.isLoading = true;
        const response = await commsService.getProgramsCommsScheduled(
          this.$route.params.id,
          {
            page: this.page,
            perPage: this.perPage,
            columnFilters: this.columnFilters,
            sort: this.sort,
          }
        );
        this.total = response.data.total;
        this.rows = response.data.items.map(item => ({
          ...item,
          id: item.id,
          email_subject: item.messages[0].subject,
          recipient:
            item.topic_recipients && item.topic_recipients.length
              ? item.topic_recipients.map(topicRecipient => {
                if (topicRecipient.recipient_type === recipientTypes.USER) {
                  return topicRecipient.recipient?.full_name;
                }
                return topicRecipient.recipient?.name;
              }).join(", ")
              : "",
          is_enabled: item.is_enabled,
          availability: useGetAvailability(item.scheduled_action),
        }));
        if (this.$route.query.scheduledId) {
          const comm = this.rows.find(r => Number(r.id) === Number(this.$route.query.scheduledId));
          if (comm) {
            this.editCommunication(comm);
          }
        }
      } catch (e) {
        console.log(e);
        this.$log.error(e);
        this.$toast(makeErrorToast("Scheduled communications list not loaded."));
      } finally {
        this.isLoading = false;
      }
    },

    editCommunication(comm) {
      this.$nextTick(()=> {
        this.$emit('edit', comm);
      });
    },

    async toggleCommunicationStatus(comm) {
      const newStatus = !comm.is_enabled;
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want ${newStatus ? 'enable' : 'disable'} this scheduled communication?`, {
          title: comm.email_subject,
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value) {
            try {
              const payload = {
                subject: comm.email_subject,
                body: comm.messages[0].body,
                channel_id: topicChannel.EMAIL,
                scheduled_actions: comm.scheduled_action,
                type_id: topicTypes.MESSAGE,
                is_enabled: !comm.is_enabled
              };

              // Add scheduled actions
              payload.is_schedulable = true;

              if (comm.scheduled_action.actionable_event_id) {
                payload.scheduled_actions = [{
                  action_id: comm.scheduled_action.action_id,
                  actionable_event_id: comm.scheduled_action.actionable_event_id,
                  relative_unit_id: comm.scheduled_action.relative_unit_id,
                  relative_value: comm.scheduled_action.relative_value,
                }];
              } else {
                payload.scheduled_actions = [{
                  action_id: comm.scheduled_action.action_id,
                  actionable_event_id: comm.scheduled_action.actionable_event_id,
                  absolute_date: formatDateTimeFromIsoDateTime(comm.scheduled_action.absolute_date, true, 'YYYY-MM-DD'),
                }];
              }

              // Add recipients
              payload.recipients = comm.topic_recipients.map(recp => {
                return {
                  recipient_id: recp.recipient_id,
                  recipient_type: recp.recipient_type,
                };
              });
              const response = await this.resolveService(comm, payload);
              if (response) {
                this.$toast(makeSuccessToast("Scheduled communication is updated"));
                this.loadItems();
              }
            } catch (e) {
              console.log(e);
              this.$toast(makeErrorToast("Scheduled communication not updated"));
              this.$log.error(e);
            }
          }
        });
    },
    resolveService(comm, payload) {
      if(this.isAdminPortal) {
        return commsService.updateCommsScheduled(comm.id, payload);
      }
      return commsService.updateProgramCommsScheduled(this.$route.params.id, comm.id, payload);
    },
    async deleteProgramCommunication(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to delete this communication? This action cannot be undone",
          {
            title: "Delete Communication",
            size: "sm",
            okVariant: "primary",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then(async value => {
          if (value) {
            try {
              const response = await commsService.deleteProgramComm(
                this.$route.params.id,
                id
              );

              if (response) {
                this.$toast(makeSuccessToast("Communication deleted successfully."));
                this.loadItems();
              }
            } catch (e) {
              const { data } = e.response;
              this.$log.error(e);
              this.$toast(makeErrorToast(data.message));
            }
          }
        });
    },

    canSendNow(communication) {
      if (communication && communication.scheduled_action) {
        const scheduledAction = communication.scheduled_action;
        const mYesterday = moment().subtract(1, "days");
        const mTomorrow = moment().add(1, "days");
        const mEventDate = moment(scheduledAction.absolute_date);
        return (
          communication.is_enabled &&
          communication.is_schedulable &&
          (scheduledAction.actionable_event_id !== null ||
            (scheduledAction.absolute_date &&
              mYesterday <= mEventDate && mEventDate <= mTomorrow))
        );
      }
      return false;
    },

    async sendNow(scheduled) {
      const h = this.$createElement;
      const messageVNode = h("div", {}, [
        h("p", "Are you sure want to send this scheduled message now?"),
        h("p", `[${scheduled.email_subject}]${scheduled.name ? " [" + scheduled.name + "]": ""}`),
      ]);
      this.$bvModal
        .msgBoxConfirm([messageVNode], {
          title: "Send Now",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "Cancel",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (value) {
            try {
              const result = await commsService.runScheduledNow(
                scheduled.program_id,
                scheduled.id
              );
              if (result) {
                this.$toast(
                  makeSuccessToast(
                    "Scheduled communication was sent successfully."
                  )
                );
              }
            } catch (e) {
              const { data } = e.response;
              this.$log.error(e);
              this.$toast(makeErrorToast(data.message));
            }
          }
        });
    },
  },
  setup() {
    const { total, perPage, page } = useAomTableConfig();

    return {
      total, 
      perPage, 
      page
    };
  }
};
</script>

<style>
.search-date-selector {
  text-align: center !important;
}
</style>

<style lang="scss">
@import "/src/assets/scss/vue-good-table.scss";
</style>