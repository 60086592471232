var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-good-table',{staticClass:"position-static",attrs:{"mode":"remote","is-loading":_vm.isLoading,"columns":_vm.columns,"rows":_vm.rows,"rtl":_vm.directionIsRTL,"search-options":{
    enabled: true,
    externalQuery: _vm.searchTerm,
  },"pagination-options":{
    enabled: true,
    perPage: _vm.perPage,
  },"style-class":"vgt-table striped"},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'email_subject')?_c('div',{staticClass:"justify-content-between flex-wrap",attrs:{"cols":"12","md":"2"}},[_c('b-link',{on:{"click":function($event){return _vm.editCommunication(props.row)}}},[_vm._v(" "+_vm._s(props.row.email_subject)+" ")])],1):(props.column.field === 'recipient')?_c('div',{staticClass:"justify-content-between flex-wrap",attrs:{"cols":"12","md":"2"}},[(!props.row.recipient)?_c('div',{staticClass:"text-danger text-left"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Please add a group to send the communications too'),expression:"'Please add a group to send the communications too'",modifiers:{"hover":true,"top":true}}],attrs:{"icon":"AlertCircleIcon","size":"32"}})],1):_vm._e(),_vm._v(" "+_vm._s(props.row.recipient)+" ")]):(props.column.field === 'actions')?_c('div',{staticClass:"vgt-center-align d-flex align-items-center",attrs:{"cols":"12","md":"2"}},[_c('b-button',{staticClass:"nav pr-0",attrs:{"variant":"outline-none"}},[_c('b-nav-item-dropdown',{staticClass:"btn-icon",attrs:{"dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{attrs:{"icon":"MoreVerticalIcon","size":"20"}})]},proxy:true}],null,true)},[_c('action-dropdown-item',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html.righttop",value:(
              !_vm.canSendNow(props.row)
                ? "\n              <div class='text-left'>Send now option activates when:\n              <br/>- Message status is enabled<br/>- Message is scheduled for a key date, \n              a participant action, or a custom date (absolute date)</div>"
                : ''
            ),expression:"\n              !canSendNow(props.row)\n                ? `\n              <div class='text-left'>Send now option activates when:\n              <br/>- Message status is enabled<br/>- Message is scheduled for a key date, \n              a participant action, or a custom date (absolute date)</div>`\n                : ''\n            ",modifiers:{"hover":true,"html":true,"righttop":true}}],attrs:{"link-class":"d-flex align-items-center","disabled":!_vm.canSendNow(props.row)},on:{"click":function($event){_vm.canSendNow(props.row) ? _vm.sendNow(props.row) : null}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"SendIcon"}}),_c('span',[_vm._v("Send Now")])],1),_c('b-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.viewSentMessageList(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"BarChart2Icon"}}),_c('span',[_vm._v("Sent messages")])],1),_c('action-dropdown-item',{attrs:{"link-class":"d-flex align-items-center"},on:{"click":function($event){return _vm.deleteProgramCommunication(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"size":"16","icon":"TrashIcon"}}),_c('span',[_vm._v("Delete")])],1)],1)],1)],1):(props.column.field === 'is_enabled')?_c('div',[_c('action-checkbox',{attrs:{"checked":props.row.is_enabled,"switch":"","inline":""},on:{"clickNativePrevent":function($event){return _vm.toggleCommunicationStatus(props.row)}}})],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('table-pagination',{attrs:{"per-page":_vm.perPage,"total":_vm.total},on:{"perPageChanged":function (value) { return props.perPageChanged({ currentPerPage: value }); },"pageChanged":function (value) { return props.pageChanged({ currentPage: value }); }}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }