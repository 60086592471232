<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ show: mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Email List -->
    <div class="email-app-list">
      <div class="d-block d-lg-none bg-white p-1">
        <feather-icon
          icon="MenuIcon"
          size="21"
          class="cursor-pointer"
          @click="mqShallShowLeftSidebar = true"
        />
      </div>
      <!-- Create template -->
      <b-card class="shadow-none">
        <div class="d-flex align-items-center justify-content-end mb-2">
          <b-button
            v-if="hasProgramAdminRole"
            variant="primary"
            style="margin-left: 10px"
            @click="handleShowAddModal"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
              size="16"
            />
            <span class="text-nowrap">Add Communication</span>
          </b-button>
          <div v-if="hasProgramAdminRole">
            <add-communication-to-program
              :show-modal="showAddCommunicationModal"
              @hiddenModal="hiddenModal"
              @confirmAddComms="onConfirmAddComms"
            />

            <confirm-add-communication-to-program
              :show-modal="showAddCommunicationModalConfirm"
              :communications="communications"
              @hiddenModal="hiddenModalConfirm"
              @commsAdded="onRefreshItems"
            />
          </div>
        </div>
        <scheduled-list
          ref="templatesList"
          @edit="editScheduled"
        />

        <email-compose
          v-model="shallShowEmailComposeModal"
          :communication="communication"
          :type="emailComposeTypes.SCHEDULED"
          :is-program-communications="true"
          @emailAdded="onRefreshItems"
          @emailSent="emailSent"
          @closeComposeModal="communication = null"
        />
      </b-card>
    </div>

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <email-left-sidebar
        :shall-show-email-compose-modal.sync="shallShowEmailComposeModal"
        :class="{ show: mqShallShowLeftSidebar }"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BCard,
  BButton,
  VBToggle,
} from "bootstrap-vue";
import { formatDateToMonthShort } from "@core/utils/filter";
import { useResponsiveAppLeftSidebarVisibility } from "@core/comp-functions/ui/app";
import EmailLeftSidebar from "@/views/apps/champion/communication/EmailLeftSidebar.vue";
import ScheduledList from "./ScheduledList.vue";
import Ripple from "vue-ripple-directive";
import EmailCompose from "@/views/components/forms/communication/EmailCompose.vue";
import { emailComposeTypes } from "@/models/enums/emailComposeTypes";
import { makeSuccessToast } from "@/libs/utils";
import AddCommunicationToProgram from "../../../admin/communications/AddCommunicationToProgram.vue";
import ConfirmAddCommunicationToProgram from "../../../admin/communications/ConfirmAddCommunicationToProgram.vue";
import { mapGetters } from 'vuex';

export default {
  components: {
    BCard,  
    BButton,
    EmailLeftSidebar,
    ScheduledList,
    EmailCompose,
    AddCommunicationToProgram,
    ConfirmAddCommunicationToProgram
  },
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  data() {
    return {
      selectedTemplate: null,
      shallShowEditorForAdd: false,
      shallShowEditorForEdit: false,
      emailComposeTypes,
      communication: null,
      showAddCommunicationModal: false,
      showAddCommunicationModalConfirm: false,
      communications: []
    };
  },
  computed: {
    ...mapGetters("profile", ["profile", 'isProgramAdmin']),
    hasProgramAdminRole() {
      return this.isProgramAdmin(Number(this.$route.params.id));
    },
  },
  methods: {
    onRefreshItems() {
      this.$refs.templatesList.loadItems();
      this.shallShowEditorForAdd = false;
      this.shallShowEditorForEdit = false;
    },
    emailSent() {
      this.$toast(
        makeSuccessToast(
          "Test email has been sent successfully to your email address."
        )
      );
    },
    editScheduled(scheduled) {
      this.shallShowEmailComposeModal = true;
      this.communication = scheduled;
    },
    hiddenModalConfirm(isShow) {
      this.showAddCommunicationModalConfirm = isShow;
    },
    hiddenModal(isShow) {
      this.showAddCommunicationModal = isShow;
    },
    onConfirmAddComms(comms) {
      this.showAddCommunicationModalConfirm = true;
      this.communications = comms;
    },
    handleShowAddModal() {
      this.showAddCommunicationModal = true;
    },
  },
  setup() {
    // Compose
    const shallShowEmailComposeModal = ref(false);

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility();

    return {
      formatDateToMonthShort,
      shallShowEmailComposeModal,
      mqShallShowLeftSidebar,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/base/pages/app-email.scss";
.content-right {
  overflow: auto;
}
</style>
